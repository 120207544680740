<template>
    <div class="Proportiongradienttwo" style="display: flex; width: 100%; height: 87%;">
        <div style="margin: auto;width: 80%;height: 70%;display: flex;justify-content: space-between;">
            <div id="chart" style="">
                <apexchart type="radialBar" height="240" :options="chartOptions" :series="series" width="200" style="float: left;margin-top: 0px;"></apexchart>
            </div>
            <!-- <apexchart type="pie" width="200" :options="chartOptions" :series="series" height="180" style="float: left;"></apexchart> -->
            <div style="margin-top: 20px;">
                <div class="Proportiontext">
                    <h3 style="color: #EFB63B;"> {{time.messageNum}} <span>次</span></h3>
                    <p>通知事件总数</p>
                </div>
                <div class="Proportiontext">
                    <h3 style="color: #0F79E6;">{{time.messagePerc}} <span>%</span></h3>
                    <p>占比数值</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.Proportiongradienttwo #SvgjsCircle1733{
    display: none;
}
.Proportiongradienttwo #SvgjsG1743{
    display: none;
}
.Proportiongradienttwo .apexcharts-datalabel-value{
    display: none;
}
.Proportiontext{
    margin-bottom:20px;
}  
.Proportiontext h3{
    font-size: 25px;
    font-weight: bold;
    margin-bottom:10px;
}
.Proportiontext span {

    font-size: 10px;
    font-weight: 500;
}
.Proportiontext p {
    color: #fff;
}
.apexcharts-canvas{
}
</style>

<script>
export default {
    name: "Proportiongradienttwo",
    data() {
        return {
            series: [0],
            chartOptions: {
                chart: {
                height: 240,
                type: 'radialBar',
                offsetY: -10
                },
                plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    dataLabels: {
                    name: {
                        fontSize: '16px',
                        color: undefined,
                        offsetY: 120
                    },
                    value: {
                        offsetY: 76,
                        fontSize: '22px',
                        color: undefined,
                        formatter: function (val) {
                        return val + "%";
                        }
                    }
                    }
                }
                },
                fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    shadeIntensity: 0.15,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 65, 91]
                },
                },
                stroke: {
                dashArray: 4
                },
                labels: [''],
            },
        };
    },
    components: {
    },
    props: ['time'],
    watch: {
        time(newvalue , oldvalue){
            this.series = [Number(newvalue.messagePerc)]
        }
    },
    methods: {
        goBack() {},
    },
    created() {},
    beforeUnmount() {},
};
</script>