<template>
    <div class="Monthlyeventstatistics">
        <apexchart ref="demoChart" type="bar" height="230" :options="chartOptions" :series="series" width="420" style="top:-20px;"></apexchart>
    </div>
</template>

<style>

</style>

<script>
import {eventMessagemonth} from "@/api/eventlog/index.js"
import dayjs from "dayjs";

    export default {
        name: "Monthlyeventstatistics",
        data() {
            return {
                monthtitles: [],
                series: [{
                    name: '告警事件',
                    data: [0,0,0],
                }, {
                    name: '广播事件',
                    data: [0,0,0],
                }, {
                    name: '通知事件',
                    data: [0,0,0],
                }],
                chartOptions: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: this.monthtitles,
                    },
                    fill: {
                        opacity: 1
                    },
                },
            };
        },
        components: {
        },
        props: [ 'datevalue', 'monthevent'],
        watch: {
            monthevent(newName, oldName){
                let ararm = [];
                let broadcast = [];
                let message = [];
                let monthtitle = [];
                    newName.forEach(element => {
                        ararm.push(element.alarmNum)
                        broadcast.push(element.broadcastNum)
                        message.push(element.messageNum)
                        monthtitle.push(element.month+'月')
                    });
                    this.$refs.demoChart.updateOptions({ xaxis: {
                        categories: monthtitle,
                    }})
                    this.series = [
                        {
                            name: '告警事件',
                            data: ararm,
                        }, {
                            name: '广播事件',
                            data: broadcast,

                        }, {
                            name: '通知事件',
                            data: message,

                        }
                    ]
            },
            datevalue(newName, oldName) {             
            }
        },
        methods: {
            goBack() {},
        },
        created() {
            // let ararm = [];
            // let broadcast = [];
            // let message = [];
            // let monthtitle = [];
            // eventMessagemonth({
            //     year: dayjs(new Date()).format('YYYY'),
            // }).then(res => {
            //     res.data.forEach(element => {
            //         ararm.push(element.alarmNum)
            //         broadcast.push(element.broadcastNum)
            //         message.push(element.messageNum)
            //         monthtitle.push(element.month+'月')
            //     });
            //     this.$refs.demoChart.updateOptions({ xaxis: {
            //         categories: monthtitle,
            //     }})
            //     this.series = [
            //         {
            //             name: '告警事件',
            //             data: ararm,
            //         }, {
            //             name: '广播事件',
            //             data: broadcast,

            //         }, {
            //             name: '通知事件',
            //             data: message,

            //         }
            //     ]
            // })
        },
        beforeUnmount() {},
    };
 </script>