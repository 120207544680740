<template>
    <div class="Eventcontent">
        <div class="Eventcontentdiv">
            <div class="Eventcontentnode" style="margin-top: 40px;">
                <h3>事件标题:</h3>
                <div style="height: 30px;"><h3>{{contentdata.title || '--'}}</h3></div>
            </div>
            <div class="Eventcontentnode">
                <h3>事件类型:</h3>
                <div style="height: 30px;">
                    <h3 v-if="contentdata.eventType == 'ALARM'">告警事件</h3>
                    <h3 v-else-if="contentdata.eventType == 'BROADCAST'">广播事件</h3>
                    <h3 v-else-if="contentdata.eventType == 'MESSAGE'">通知事件</h3>
                    <h3 v-else>--</h3>
                </div>
            </div>
            <div class="Eventcontentnode">
                <h3>事件地点:</h3>
                <div style="height: 30px;"><h3>{{contentdata.eventLocation || '--'}}</h3></div>
            </div>
            <div class="Eventcontentnode" style="height: 182px; overflow: hidden;">
                <h3>事件内容:</h3>
                <div style="height: 182px;"><h3>{{contentdata.content || '--'}}
                </h3></div>
            </div>
        </div>
    </div>
</template>

<style>
    .Eventcontent{
        width: 100%;
        height: 100%;  
        padding-top: 20px;
    }
    .Eventcontentdiv{
        width: 87%;
        margin: 0 auto;
        height: 100%;
    }
    .Eventcontentnode{
        width: 95%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        margin: 20px auto;
        /* margin-bottom: 15px; */
    }
    .Eventcontentnode>h3{
        font-size: 17px;
        color: #ffffff;
        width: 13.7%;
        text-align: left;
        line-height: 40px;
    }
    .Eventcontentnode>div{
        width: 85%;
        background: #333648;
        padding: 10px;
        border-radius: 5px;
        color: #ffffff;
        line-height: 30px;
    }
    .Eventcontentnode>div>h3{
        display: inline-block;
        word-break:break-all;
        width: 100%;
    }
</style>

<script>
import {eventMessagedetails} from "@/api/eventlog/index.js"
import dayjs from "dayjs";
import moment from 'moment'
  export default {
        name: "Eventstatistics",
        data() {
            return {
               data:null,
               contentdata:''
            };
        },
        components: {
        },
        props: [ 'Eventcontentdetailsid' ],
        watch: {
            Eventcontentdetailsid(newName, oldName) {
                eventMessagedetails({
                    id: newName
                }).then(res => {
                    this.contentdata = res.data
                })
                console.log(newName, oldName)
            }
        },
        methods: {
            moment,
            goBack() {},
        },
        created() {
        },
        beforeUnmount() {},
    };
</script>