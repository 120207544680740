<!--
 * @Author: PC
 * @Date: 2022-01-04 15:34:32
 * @LastEditTime: 2022-01-08 22:03:21
 * @Description: 请您输入文件描述
-->
<template>
  <div class="Monthlyeventstatisticsline">
    <apexchart ref="demoChart" type="line" height="220" :options="chartOptions" :series="series" width="420" style="top: -20px"></apexchart>
  </div>
</template>

<style></style>

<script>
import { eventMessageday } from "@/api/eventlog/index.js";
import dayjs from "dayjs";
export default {
  name: "Monthlyeventstatisticsline",
  data() {
    return {
      monthtitles: [],
      series: [
        {
          name: "告警事件",
          data: [44, 55, 57, 56, 61, 58],
        },
        {
          name: "广播事件",
          data: [76, 85, 101, 98, 87, 105],
        },
        {
          name: "通知事件",
          data: [35, 41, 36, 26, 45, 48],
        },
      ],
      chartOptions: {
        chart: {
          height: 220,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val;
                },
              },
            },
          ],
        },
      },
    };
  },
  components: {},
  props: ["datevalue"],
  watch: {
    datevalue(newName, oldName) {
      let ararm = [];
      let broadcast = [];
      let message = [];
      let monthtitle = [];
      eventMessageday({
        eventTimeBegin: dayjs(newName[0]).format("YYYY-MM-DD"),
        eventTimeEnd: dayjs(newName[1]).format("YYYY-MM-DD"),
      }).then((res) => {
        console.log("🚀 ~ file: Monthlyeventstatisticsline.vue ~ line 88 ~ datevalue ~ res", res);
        res.data.forEach((element) => {
          ararm.push(element.alarmNum);
          broadcast.push(element.broadcastNum);
          message.push(element.messageNum);
          monthtitle.push(element.eventTime);
        });
        this.$refs.demoChart.updateOptions({
          xaxis: {
            categories: monthtitle,
          },
        });
        this.series = [
          {
            name: "告警事件",
            data: ararm,
          },
          {
            name: "广播事件",
            data: broadcast,
          },
          {
            name: "通知事件",
            data: message,
          },
        ];
      });
    },
  },
  methods: {
    goBack() {},
  },
  created() {
    let ararm = [];
    let broadcast = [];
    let message = [];
    let monthtitle = [];
    eventMessageday({
      eventTimeBegin: dayjs(new Date()).format("YYYY-MM-DD"),
      eventTimeEnd: dayjs(new Date()).format("YYYY-MM-DD"),
    }).then((res) => {
      res.data.forEach((element) => {
        ararm.push(element.alarmNum);
        broadcast.push(element.broadcastNum);
        message.push(element.messageNum);
        monthtitle.push(element.eventTime);
      });
      this.$refs.demoChart.updateOptions({
        xaxis: {
          categories: monthtitle,
        },
      });
      this.series = [
        {
          name: "告警事件",
          data: ararm,
        },
        {
          name: "广播事件",
          data: broadcast,
        },
        {
          name: "通知事件",
          data: message,
        },
      ];
    });
  },
  beforeUnmount() {},
};
</script>
