<template>
    <div class="Proportiongradient" style="display: flex; width: 100%; height: 87%;">
        <div style="margin: auto;width: 80%;height: 70%;display: flex;justify-content: space-between;">
            <div id="chart" style="">
                <apexchart type="radialBar" height="240" :options="chartOptions" :series="series" width="200" style="float: left;margin-top: -30px;"></apexchart>
            </div>
            <!-- <apexchart type="pie" width="200" :options="chartOptions" :series="series" height="180" style="float: left;"></apexchart> -->
            <div style="margin-top: 20px;">
                <div class="Proportiontext">
                    <h3 style="color: #EFB63B;"> {{time.broadcastNum}} <span>次</span></h3>
                    <p>广播事件总数</p>
                </div>
                <div class="Proportiontext">
                    <h3 style="color: #0F79E6;">{{time.broadcastPerc}} <span>%</span></h3>
                    <p>占比数值</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.Proportiongradient #SvgjsCircle1733{
    display: none;
}
.Proportiongradient #SvgjsG1743{
    display: none;
}
.Proportiongradient .apexcharts-datalabel-value{
    display: none;
}
.Proportiontext{
    margin-bottom:20px;
}  
.Proportiontext h3{
    font-size: 25px;
    font-weight: bold;
    margin-bottom:10px;
}
.Proportiontext span {

    font-size: 10px;
    font-weight: 500;
}
.Proportiontext p {
    color: #fff;
}
.apexcharts-canvas{
}
</style>

<script>
export default {
    name: "Proportiongradient",
    data() {
        return {
            series: [0],
            chartOptions: {
                chart: {
                type: 'radialBar',
                toolbar: {
                    show: true
                }
                },
                plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 225,
                    hollow: {
                        margin: 0,
                        size: '40%',
                    },
                
                    dataLabels: {
                    show: true,
                    name: {
                        offsetY: -10,
                        show: true,
                        fontSize: '17px'
                    }
                 }
                }
                },
                fill: {
                type: 'gradient',
                },
                stroke: {
                    lineCap: 'round'
                },
                labels: [''],
            },
        }
    },
    components: {
    },
    props: ['time'],
    watch: {
        time(newvalue , oldvalue){
            this.series = [Number(newvalue.broadcastPerc)]
        }
    },
    methods: {
        goBack() {},
    },
    created() {},
    beforeUnmount() {},
};
</script>
          