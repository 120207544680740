<template>
	<div class="Eventstaticsmain">
		<div class="Eventstaticsmaintime">
			<div class="Eventstaticsmainbox">
				<div class="Eventstaticsmaintimechild">
					<p>{{ titledata[0] }}</p>
					<div>
						<span></span>
						<i></i>
					</div>
					<div>
						<span></span>
						<i></i>
					</div>
				</div>
				<div class="Eventstaticsmaintimechild">
					<p>{{ titledata[1] }}</p>
					<div>
						<span></span>
						<i></i>
					</div>
					<div>
						<span></span>
						<i></i>
					</div>
				</div>
				<div class="Eventstaticsmaintimechild">
					<p>{{ titledata[2] }}</p>
					<div>
						<span></span>
						<i></i>
					</div>
					<div>
						<span></span>
						<i></i>
					</div>
				</div>
				<div class="Eventstaticsmaintimechild">
					<p>{{ titledata[3] }}</p>
					<div>
						<span></span>
						<i></i>
					</div>
					<div>
						<span></span>
						<i></i>
					</div>
				</div>
				<div class="Eventstaticsmaintimechild">
					<p>{{ titledata[4] }}</p>
					<div>
						<span></span>
						<i></i>
					</div>
					<div>
						<span></span>
						<i></i>
					</div>
				</div>
				<div class="Eventstaticsmaintimechild">
					<p>{{ titledata[5] }}</p>
					<div>
						<span></span>
						<i></i>
					</div>
					<div>
						<span></span>
						<i></i>
					</div>
				</div>
			</div>
		</div>
		<div class="Eventstaticsmaintext">
			<div class="redevent">
				<div></div>
				告警事件
			</div>
			<div class="yellowevent">
				<div></div>
				广播事件
			</div>
			<div class="greenevent">
				<div></div>
				通知事件
			</div>
		</div>

		<div
			style="
				width: 650px;
				height: 160px;
				margin: 30px auto;
				position: relative;
			"
		>
			<div class="Eventstaticsmaindatabox">
				<div>
					<span></span>
					<span></span>
					<span></span>
					<p class="eventtext">{{ datas.alarmPerc }}%</p>
					<template v-if="datas.alarmPerc < 10">
						<div :style="{ height: '10%' }"></div>
					</template>
					<template v-else>
						<div :style="{ height: datas.alarmPerc + '%' }"></div>
					</template>
				</div>
				<div>
					<span></span>
					<span></span>
					<span></span>
					<p class="eventtext2">{{ datas.broadcastPerc }}%</p>
					<template v-if="datas.broadcastPerc < 10">
						<div :style="{ height: '10%' }"></div>
					</template>
					<template v-else>
						<div
							:style="{ height: datas.broadcastPerc + '%' }"
						></div>
					</template>
				</div>
				<div>
					<span></span>
					<span></span>
					<span></span>
					<p class="eventtext3">{{ datas.messagePerc }}%</p>
					<template v-if="datas.messagePerc < 10">
						<div :style="{ height: '10%' }"></div>
					</template>
					<template v-else>
						<div :style="{ height: datas.messagePerc + '%' }"></div>
					</template>
				</div>
			</div>
			<div class="Eventstaticsmainnode">
				<!-- <div class="Eventstaticsmainnodebg"> -->
				<!-- <div class="Evesntstaticsmaindata"> -->
				<!-- <div class="datanode">
                            <div class="top"></div>
                            <div class="center"></div>
                            <div class="right"></div>
                        </div>
                        <div class="datanode">
                            <div class="top"></div>
                            <div class="center"></div>
                            <div class="right"></div>
                        </div>
                        <div class="datanode">
                            <div class="top"></div>
                            <div class="center"></div>
                            <div class="right"></div>
                        </div> -->
				<!-- </div> -->
				<!-- </div> -->
			</div>
		</div>
	</div>
</template>

<style>
.Eventstaticsmain {
	width: 100%;
	height: 100%;
}
.Eventstaticsmaintime {
	margin: 0 auto;
	width: 60%;
	display: flex;
	justify-content: space-around;
	/* height: 30%; */
	padding-top: 5%;
}
.Eventstaticsmainbox {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
}
.Eventstaticsmaintimechild {
	width: 60px;
	height: 110px;
	box-shadow: rgb(11, 234, 235) 0px 0px 18px inset;
	position: relative;
}
.Eventstaticsmaintimechild > div {
	width: 100%;
	height: 50%;
	position: relative;
}
.Eventstaticsmaintimechild > div::after {
	content: "";
	position: absolute;
	width: 10px;
	height: 10px;
	border-top: 1px solid #29b3e9;
	border-left: 1px solid #29b3e9;
	top: 0;
	left: 0;
}
.Eventstaticsmaintimechild > div::before {
	content: "";
	position: absolute;
	width: 10px;
	height: 10px;
	border-top: 1px solid #29b3e9;
	border-right: 1px solid #29b3e9;
	top: 0;
	right: 0;
}
.Eventstaticsmaintimechild > div > span::after {
	content: "";
	position: absolute;
	width: 10px;
	height: 10px;
	border-bottom: 1px solid #29b3e9;
	border-left: 1px solid #29b3e9;
	bottom: 0;
	left: 0;
}
.Eventstaticsmaintimechild > div > span::before {
	content: "";
	position: absolute;
	width: 10px;
	height: 10px;
	border-bottom: 1px solid #29b3e9;
	border-right: 1px solid #29b3e9;
	bottom: 0;
	right: 0;
}
.Eventstaticsmaintimechild > p {
	font-size: 70px;
	text-align: center;
	color: #fff;
	position: absolute;
	z-index: 999;
	left: 8px;
	top: 14px;
}
.Eventstaticsmaintext {
	display: flex;
	justify-content: space-around;
	color: #fff;
	width: 40%;
	margin: 30px auto;
	margin-bottom: 5%;
}
.Eventstaticsmaintext div {
	position: relative;
}
.redevent div {
	width: 8px;
	height: 8px;
	background: #d42d21;
	float: left;
	margin: 3px 8px;
}
.yellowevent div {
	width: 8px;
	height: 8px;
	background: #efb63b;
	float: left;
	margin: 3px 8px;
}
.greenevent div {
	width: 8px;
	height: 8px;
	background: #29dbc9;
	float: left;
	margin: 3px 8px;
}
.Eventstaticsmainnode {
	/* margin: 0 auto; */
	/* border-bottom: 150px solid #2c95c3;
        border-left: 100px solid transparent;
        border-right: 100px solid transparent; */
	height: 94px;
	width: 650px;
	position: absolute;
	bottom: 0px;
	background: url("../../assets/image/components/EventLog/台边.png") no-repeat;
	z-index: 1;
	/* position: relative;
        top: 40px; */
}
.Eventstaticsmainnodebg {
	width: 80%;
	height: 100%;
	margin: 0 auto;
	/* margin: 0 auto; */
	/* border-bottom: 140px solid #202331;
        border-left: 96px solid transparent;
        border-right: 96px solid transparent; */
	/* height: 0;
        width: 97%;
        position: absolute;
        left: -88px;
        top: 4px; */
}
.Eventstaticsmaindata {
	width: 100%;
	display: flex;
	position: absolute;
	top: -40px;
	left: 35px;
}
.datanode {
	width: 100%;
	transform-style: preserve-3d;
	transform: rotateX(-20deg) rotateY(-22deg);
}
.datanode div {
	width: 72px;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
}
.Eventstaticsmaindatabox {
	width: 50%;
	/* height: 100%; */
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	position: relative;
	z-index: 111;
}
.Eventstaticsmaindatabox img {
	width: 100%;
	/* height: 14px; */
	position: relative;
	top: -3px;
	object-fit: cover;
}
.Eventstaticsmaindatabox > div:nth-child(1) {
	width: 50px;
	height: 124px;
	background: url("../../assets/image/components/EventLog/red透明外框.png")
		no-repeat;
	background-size: 100%;
	position: relative;
	cursor: pointer;
}
.Eventstaticsmaindatabox > div:nth-child(1):hover .eventtext {
	opacity: 1;
}
.Eventstaticsmaindatabox > div:nth-child(2):hover .eventtext2 {
	opacity: 1;
}
.Eventstaticsmaindatabox > div:nth-child(3):hover .eventtext3 {
	opacity: 1;
}
.eventtext {
	position: absolute;
	width: 70px;
	height: 25px;
	border-radius: 5px;
	background: #f56c6c;
	color: #fef0f0;
	text-align: center;
	line-height: 25px;
	z-index: 999;
	top: 60px;
	right: 50px;
	opacity: 0;
	transition: opacity 1s;
}
.eventtext2 {
	position: absolute;
	width: 70px;
	height: 25px;
	border-radius: 5px;
	background: rgb(254, 176, 25);
	color: #fef0f0;
	text-align: center;
	line-height: 25px;
	z-index: 999;
	top: 60px;
	right: 50px;
	opacity: 0;
	transition: opacity 1s;
}
.eventtext3 {
	position: absolute;
	width: 70px;
	height: 25px;
	border-radius: 5px;
	background: #29dbc9;
	color: #fef0f0;
	text-align: center;
	line-height: 25px;
	z-index: 999;
	top: 60px;
	right: 50px;
	opacity: 0;
	transition: opacity 1s;
}
.Eventstaticsmaindatabox > div:nth-child(1) > span:nth-child(1) {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	/* background: #D42D21; */
	background: #d42d21;
	position: absolute;
	top: 2%;
	left: 35%;
	animation: more 5s linear infinite;
}
.Eventstaticsmaindatabox > div:nth-child(1) > span:nth-child(2) {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #d42d21;
	position: absolute;
	top: -2%;
	left: 50%;
	animation: more2 4s linear infinite;
}
.Eventstaticsmaindatabox > div:nth-child(1) > span:nth-child(3) {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #d42d21;
	position: absolute;
	top: -2%;
	left: 60%;
	animation: more3 2s linear infinite;
}
.Eventstaticsmaindatabox > div:nth-child(1) > div {
	width: 100%;
	height: 80px;
	background: url("../../assets/image/components/EventLog/高红.png") no-repeat;
	background-size: 100% auto;
	position: absolute;
	bottom: 0px;
	overflow: hidden;
}
.Eventstaticsmaindatabox > div:nth-child(1) > div::before {
	content: "";
	width: 100%;
	height: 12px;
	background: url("../../assets/image/components/EventLog/red实体上面.png")
		no-repeat;
	background-size: 101%;
	position: absolute;
	top: 0px;
	left: 0px;
}
.Eventstaticsmaindatabox > div:nth-child(1)::before {
	content: "";
	position: absolute;
	width: 50px;
	height: 50px;
	background: url("../../assets/image/components/EventLog/red光.png")
		no-repeat;
	background-size: 100%;
	top: -35px;
}
.Eventstaticsmaindatabox > div:nth-child(2) {
	width: 50px;
	height: 124px;
	background: url("../../assets/image/components/EventLog/yellow透明外框.png")
		no-repeat;
	background-size: 100%;
	position: relative;
	cursor: pointer;
}
.Eventstaticsmaindatabox > div:nth-child(2) > div {
	width: 100%;
	height: 80px;
	background: url("../../assets/image/components/EventLog/高黄.png") no-repeat;
	background-size: 100%;
	position: absolute;
	bottom: 0px;
	overflow: hidden;
}
.Eventstaticsmaindatabox > div:nth-child(2) > span:nth-child(1) {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #efb63b;
	position: absolute;
	top: -2%;
	left: 35%;
	animation: more 6s linear infinite;
}
.Eventstaticsmaindatabox > div:nth-child(2) > span:nth-child(2) {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #efb63b;
	position: absolute;
	top: -15%;
	left: 50%;
	animation: more2 2s linear infinite;
}
.Eventstaticsmaindatabox > div:nth-child(2) > span:nth-child(3) {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #efb63b;
	position: absolute;
	top: -2%;
	left: 35%;
	animation: more3 4s linear infinite;
}
.Eventstaticsmaindatabox > div:nth-child(2) > div::before {
	content: "";
	width: 100%;
	height: 12px;
	background: url("../../assets/image/components/EventLog/yellow实体上面.png")
		no-repeat;
	background-size: 101%;
	position: absolute;
	top: 0px;
	left: 0px;
}
.Eventstaticsmaindatabox > div:nth-child(2)::before {
	content: "";
	position: absolute;
	width: 50px;
	height: 50px;
	background: url("../../assets/image/components/EventLog/yellow光.png")
		no-repeat;
	background-size: 100%;
	top: -35px;
}
.Eventstaticsmaindatabox > div:nth-child(3) {
	width: 50px;
	height: 124px;
	background: url("../../assets/image/components/EventLog/green透明外框.png")
		no-repeat;
	background-size: 100%;
	position: relative;
	cursor: pointer;
}
.Eventstaticsmaindatabox > div:nth-child(3) > div {
	width: 100%;
	height: 80px;
	background: url("../../assets/image/components/EventLog/高蓝.png") no-repeat;
	background-size: 100%;
	position: absolute;
	bottom: 0px;
	overflow: hidden;
}
.Eventstaticsmaindatabox > div:nth-child(3) > span:nth-child(1) {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #29dbc9;
	position: absolute;
	top: -2%;
	left: 35%;
	animation: more 7s linear infinite;
}
.Eventstaticsmaindatabox > div:nth-child(3) > span:nth-child(2) {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #29dbc9;
	position: absolute;
	top: -15%;
	left: 50%;
	animation: more2 3s linear infinite;
}
.Eventstaticsmaindatabox > div:nth-child(3) > span:nth-child(3) {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #29dbc9;
	position: absolute;
	top: -2%;
	left: 35%;
	animation: more3 2s linear infinite;
}
.Eventstaticsmaindatabox > div:nth-child(3) > div::before {
	content: "";
	width: 100%;
	height: 12px;
	background: url("../../assets/image/components/EventLog/green实体上面.png")
		no-repeat;
	background-size: 101%;
	position: absolute;
	top: 0px;
	left: 0px;
}
.Eventstaticsmaindatabox > div:nth-child(3)::before {
	content: "";
	position: absolute;
	width: 50px;
	height: 50px;
	background: url("../../assets/image/components/EventLog/green光.png")
		no-repeat;
	background-size: 100%;
	background-position: 0 0;
	top: -35px;
}
/* 0%,100%{
            top: -2%;
            left: 35%;
        }
        50%{
            top: -20%;
            left: 70%;    
        } */
@keyframes more {
	0% {
		top: 2%;
		left: 35%;
	}
	40% {
		top: -10%;
		left: 35%;
	}
	60% {
		top: -25%;
		left: 35%;
	}
	100% {
		top: -50%;
		left: 35%;
		opacity: 0;
	}
}
@keyframes more2 {
	0% {
		top: -2%;
		left: 50%;
	}
	40% {
		top: -10%;
		left: 50%;
	}
	60% {
		top: -25%;
		left: 50%;
	}
	100% {
		top: -50%;
		left: 50%;
		opacity: 0;
	}
}

@keyframes more3 {
	0% {
		top: -2%;
		left: 60%;
	}
	40% {
		top: -10%;
		left: 60%;
	}
	60% {
		top: -25%;
		left: 60%;
	}
	100% {
		top: -50%;
		left: 60%;
		opacity: 0;
	}
}
</style>

<script>
import {
	eventMessageall,
	eventMessageperc,
	gettime,
} from "@/api/eventlog/index.js";

export default {
	name: "Monthlyeventstatisticsmain",
	data() {
		return {
			datas: "",
			count: 0,
			titledata: [],
			timedata: "",
		};
	},
	components: {},
	props: {},
	methods: {
		goBack() {},
		getTimeCycle(timer) {
			const _self = this;

			setTimeout(() => {
				eventMessageall().then((res) => {
					this.datas = res.data;
					this.count =
						Number(this.datas.alarmNum) +
						Number(this.datas.broadcastNum) +
						Number(this.datas.messageNum);
					let value = 6 - String(this.count).split("").length;
					for (let i = 0; i < value; i++) {
						this.titledata.push("0");
					}
					String(this.count)
						.split("")
						.forEach((e) => {
							this.titledata.push(e);
						});
					_self.getTimeCycle(timer);
				});
			}, timer * 1000);
		},
	},
	created() {
		const _self = this;
		eventMessageall().then((res) => {
			this.datas = res.data;
			this.count =
				Number(this.datas.alarmNum) +
				Number(this.datas.broadcastNum) +
				Number(this.datas.messageNum);
			let value = 6 - String(this.count).split("").length;
			for (let i = 0; i < value; i++) {
				this.titledata.push("0");
			}
			String(this.count)
				.split("")
				.forEach((e) => {
					this.titledata.push(e);
				});
		});

		gettime().then((res) => {
			const timer = res.data != null ? res.data : 5;
			_self.getTimeCycle(timer);
		});
	},
	beforeUnmount() {},
};
</script>
