<template>
  <div class="EventLog">
    <HomeHead :isHome="true" />

    <!-- <img :src="require('@/assets/image/pages/EventLog/事件日志二级页面.png')" alt="" style="width: 100%;"> -->
    <div class="back_row">
      <!-- <Navigation :isNavigation="false" /> -->
      <div class="navigation">
        <el-button class="navigation-btn" @click="returnclick">
          <span class="navigation-btn-text">返回主页面</span>
        </el-button>
      </div>
    </div>
    <div class="EventBox">
      <div style="width: 480px">
        <Box style="width: 100%; height: 332px; margin-bottom: 10px">
          <Boxtitle>
            <div>
              <h4>事件日志</h4>
              <el-date-picker v-model="value1" type="date" placeholder="选择日期" class="Eventstatisticstime" />
            </div>
          </Boxtitle>
          <Eventstatistics :datevalue="value1" @EventstatisticsData="getEventstatisticsData" />
        </Box>
        <Box style="width: 100%; height: 270px; margin-bottom: 10px">
          <Boxtitle>
            <div>
              <h4>每月事件统计</h4>
              <el-date-picker v-model="value2" type="monthrange" @change="changetimetwo" class="Eventstatisticstime"
                :start-placeholder="Monthlyeventstatisticstimetext" :end-placeholder="Monthlyeventstatisticstimetext" />
            </div>
          </Boxtitle>
          <Monthlyeventstatistics :datevalue="value2" :monthevent="montheventdata" />
        </Box>
        <Box style="width: 100%; height: 270px; margin-bottom: 10px">
          <Boxtitle>
            <div>
              <h4>每日事件统计</h4>
              <el-date-picker v-model="value3" type="daterange" class="Monthlyeventstatisticstime"
                :start-placeholder="Monthlyeventstatisticslinetext" :end-placeholder="Monthlyeventstatisticslinetext"
                :disabledDate="disabledDate" @change="changetimethree" />
            </div>
          </Boxtitle>
          <Monthlyeventstatisticsline :datevalue="daytime" />
        </Box>
      </div>

      <div style="width: 830px">
        <Box style="width: 100%; height: 442px; margin-bottom: 20px">
          <Eventstatisticsmain />
        </Box>
        <Box style="width: 100%; height: 442px; margin-bottom: 10px">
          <Boxtitle>
            <div>
              <h4>事件内容详情</h4>
            </div>
          </Boxtitle>
          <Eventcontent :Eventcontentdetailsid="EventstatisticsId" />
        </Box>
      </div>

      <div style="width: 480px">
        <el-date-picker v-model="value4" type="daterange" :start-placeholder="eventlogtimetext"
          :end-placeholder="eventlogtimetext" @change="changetimefour" class="eventlogalltime"
          :disabledDate="disabledDate" />
        <Box style="width: 100%; height: 276px; margin-bottom: 10px">
          <Boxtitle>
            <div>
              <h4>告警事件占比</h4>
            </div>
          </Boxtitle>
          <Proportionevents :time="proportion" />
        </Box>
        <Box style="width: 100%; height: 276px; margin-bottom: 10px">
          <Boxtitle>
            <div>
              <h4>广播事件占比</h4>
            </div>
          </Boxtitle>
          <Proportiongradient :time="proportion" />
        </Box>
        <Box style="width: 100%; height: 276px; margin-bottom: 10px">
          <Boxtitle>
            <div>
              <h4>通知事件占比</h4>
            </div>
          </Boxtitle>
          <Proportiongradienttwo :time="proportion" />
        </Box>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHead from "components/HomeHead";
import Box from "components/Box";
import Boxtitle from "components/Boxtitle";
import Eventstatistics from "components/Eventlog/Eventstatistics";
import Monthlyeventstatistics from "components/Eventlog/Monthlyeventstatistics";
import Monthlyeventstatisticsline from "components/Eventlog/Monthlyeventstatisticsline";
import Eventstatisticsmain from "components/Eventlog/Eventstatisticsmain";
import Eventcontent from "components/Eventlog/Eventcontent";
import Proportionevents from "components/Eventlog/Proportionevents";
import Proportiongradient from "components/Eventlog/Proportiongradient";
import Proportiongradienttwo from "components/Eventlog/Proportiongradienttwo";
import dayjs from "dayjs";

// api
import {
  eventMessagelist,
  eventMessageperc,
  eventMessageday,
  eventMessagemonth,
} from "@/api/eventlog/index.js";

export default {
  name: "EventLog",
  data() {
    return {
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      Eventstatisticstext: "选择时间",
      Monthlyeventstatisticstext: "选择时间",
      Monthlyeventstatisticslinetext: "选择时间",
      eventlogtimetext: "选择时间",
      Monthlyeventstatisticstimetext: "选择时间",
      contentdata: "",
      EventstatisticsId: "",
      proportion: "",
      daytime: "",
      montheventdata: [],
    };
  },
  computed: {},
  components: {
    HomeHead,
    Box,
    Boxtitle,
    Eventstatistics,
    Monthlyeventstatistics,
    Monthlyeventstatisticsline,
    Eventstatisticsmain,
    Eventcontent,
    Proportionevents,
    Proportiongradient,
    Proportiongradienttwo,
  },
  props: {},

  mounted() {
    // 请求初始化数据
    this.fetch();
  },

  methods: {
    // 请求初始化数据
    fetch() {
      // 获取事件日志
      // 子组件采用监听value方式获取数据，当前只需设置默认请求日期即可
      this.value1 = dayjs().format("YYYY-MM-DD");

      // 获取默认当前时间半年之前的每月事件统计数据
      this.getDefaultDateMonthlyTaskData();

      // 获取默认当前时间半个月之前的每日事件&&告警事件占比广播事件占比通知事件统计数据
      this.getDefaultDateDayAndOtherTaskData();
    },

    // 获取默认当前时间半年之前的每月事件统计数据
    getDefaultDateMonthlyTaskData() {
      const startDate = dayjs().subtract(5, "month").format("YYYY-MM-DD"); //开始时间
      const endDate = dayjs().format("YYYY-MM-DD"); //结束时间
      const date = [startDate, endDate]; //起止时间
      // 获取每月事件统计数据
      this.changetimetwo(date);
    },

    // 获取默认当前时间半个月之前的每日事件&&告警事件占比广播事件占比通知事件统计数据
    getDefaultDateDayAndOtherTaskData() {
      const startDate = dayjs().subtract(14, "day").format("YYYY-MM-DD"); //开始时间
      const endDate = dayjs().format("YYYY-MM-DD"); //结束时间
      const date = [startDate, endDate]; //起止时间
      // 获取每日事件统计数据
      this.changetimethree(date);
      // 获取告警事件占比广播事件占比通知事件占比数据
      this.changetimefour(date);
    },

    // 获取每月事件统计数据
    changetimetwo(data) {
      eventMessagemonth({
        startDate: dayjs(data[0]).format("YYYY-MM-DD"),
        endDate: dayjs(data[1]).format("YYYY-MM-DD"),
      }).then((res) => {
        this.montheventdata = res.data;
      });
      this.value2 =
        dayjs(data[0]).format("YYYY-MM-DD") +
        " - " +
        dayjs(data[1]).format("YYYY-MM-DD");
      this.Monthlyeventstatisticstimetext =
        dayjs(data[0]).format("YYYY-MM-DD") +
        " - " +
        dayjs(data[1]).format("YYYY-MM-DD");
    },

    // 获取每日事件统计数据
    changetimethree(data) {
      this.value3 =
        dayjs(data[0]).format("YYYY-MM-DD") +
        " - " +
        dayjs(data[1]).format("YYYY-MM-DD");
      this.Monthlyeventstatisticslinetext =
        dayjs(data[0]).format("YYYY-MM-DD") +
        " - " +
        dayjs(data[1]).format("YYYY-MM-DD");
      this.daytime = [data[0], data[1]];
    },
    changetimefour(data) {
      eventMessageperc({
        eventTimeBegin: dayjs(data[0]).format("YYYY-MM-DD"),
        eventTimeEnd: dayjs(data[1]).format("YYYY-MM-DD"),
      }).then((res) => {
        this.proportion = res.data;
      });
      this.value4 =
        dayjs(data[0]).format("YYYY-MM-DD") +
        " - " +
        dayjs(data[1]).format("YYYY-MM-DD");
      this.eventlogtimetext =
        dayjs(data[0]).format("YYYY-MM-DD") +
        " - " +
        dayjs(data[1]).format("YYYY-MM-DD");
    },
    returnclick() {
      this.$router.push({ path: "/" });
    },
    getEventstatisticsData(data) {
      this.EventstatisticsId = data;
    },
    disabledDate(date) {
      return date.getTime() > Date.now() - 8.64e6;
    },
    goBack() {
      window.location.href = "about:blank";
      window.close();
    },
  },
};
</script>

<style lang="scss">
.EventLog {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1920px;
  height: 1080px;
  background: #1f1f2b;
  z-index: 1;
}

.returnicon {
  width: 0.09rem;
  height: 0.09rem;
  background: url("../../assets/icons/returnbtn.png") no-repeat;
  background-size: 100%;
  display: inline-block;
  position: relative;
  top: 3px;
}

.back_row {
  margin-top: 70px;
  padding: 0 20px;
  position: relative;
  z-index: 1000;
}

.EventBox {
  width: calc(100% - 40px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  transform-origin: 0% 0%;
  transition: transform 0.2s linear;
}

/* .EventBox>div{
        margin-left: 20px;
      } */
.apexcharts-toolbar {
  display: none !important;
}

.eventlogalltime {
  background: #5b657f;
  width: 100% !important;
  height: 40px;
  color: #fff;
  border-radius: 4px;
  line-height: 30px;
  margin-bottom: 10px;
}

.el-input {
  --el-input-font-color: none;
}

.eventlogalltime .el-input__icon {
  display: none;
}

.eventlogalltime .el-range-separator {
  display: none;
}

.eventlogalltime .el-range-input {
  background: #5b657f;
  text-align: center;
  width: 100%;
}

.eventlogalltime .el-range-input:nth-child(2) {
  display: none;
}

.EventLog .Eventstatisticstime {
  background: #5b657f;
  width: 170px;
  height: 30px;
  margin-top: 5px;
  color: #fff;
  border-radius: 4px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
  margin-right: 10px;

  input {
    cursor: default;
    text-align: center;
  }
}

.EventLog {
  .navigation {
    display: flex;
    align-items: center;
    padding: 0 0 20px 0;
  }

  .navigation-btn {
    width: 140px;
    height: 44px;
    background: rgba(95, 107, 138, 0.7);
    border-radius: 4px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    margin-right: 15px;
    color: #fff;
    transition: background 0.2s ease-out;

    &:hover {
      // border: #fff 2px solid;
      background: rgba(95, 107, 138, 1);
    }

    &:active {
      opacity: 0.5;
    }
  }

  .navigation-btn-text {}

  .active {
    background: rgba(28, 117, 182, 0.7);

    &:hover {
      // border: #fff 2px solid;
      background: rgba(28, 117, 182, 0.7);
    }
  }
}
</style>
