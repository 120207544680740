<!--
 * @Author: PC
 * @Date: 2022-01-04 15:34:32
 * @LastEditTime: 2022-01-08 20:46:49
 * @Description: 请您输入文件描述
-->
<template>
  <div class="statistics">
    <div class="eventclass">
      <div @click="changedata('gaojing')">
        <div class="eventred"></div>
        <p style="font-size: 14px; text-align: center; color: #d42d21" v-if="contentdata.alarmNum > 999">告警事件(999+)</p>
        <p style="font-size: 14px; text-align: center; color: #d42d21" v-else>告警事件({{ contentdata.alarmNum }})</p>
      </div>
      <div @click="changedata('guangbo')">
        <div class="eventyellow"></div>
        <p style="font-size: 14px; text-align: center; color: #efb63b" v-if="contentdata.broadcastNum > 999">广播事件(999+)</p>
        <p style="font-size: 14px; text-align: center; color: #efb63b" v-else>广播事件({{ contentdata.broadcastNum }})</p>
      </div>
      <div @click="changedata('tozhi')">
        <div class="eventgreen"></div>
        <p style="font-size: 14px; text-align: center; color: #29dbc9" v-if="contentdata.messageNum > 999">通知事件(999+)</p>
        <p style="font-size: 14px; text-align: center; color: #29dbc9" v-else>通知事件({{ contentdata.messageNum }})</p>
      </div>
    </div>

    <div class="eventtitle">
      <div style="width: 30%">
        <p style="margin-left: 10px; font-size: 14px">事件标题</p>
      </div>
      <div style="width: 20%">
        <p style="margin-left: 10px; font-size: 14px">时间</p>
      </div>
      <div style="width: 50%">
        <p style="margin-left: 10px; font-size: 14px">内容</p>
      </div>
    </div>

    <div class="eventcontent">
      <template v-if="contentdata.eventMessageList" v-for="i in contentdata.eventMessageList">
        <template v-if="i.eventType == 'ALARM'">
          <div
            :class="{
              eventcontentItem: true,
              eventcontentItemActived: i.id === defaultMesssageId ? true : false,
            }"
            style="color: #d42d21"
            @click="clickdetails(i.id)"
          >
            <div style="width: 35%">
              <p style="margin-left: 10px">{{ i.title }}</p>
            </div>
            <div style="width: 15%">
              <p style="margin-left: 10px">
                {{ moment(i.eventTime).format("MM-DD") }}
              </p>
            </div>
            <div style="width: 50%">
              <p style="margin-left: 10px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                {{ i.content }}
              </p>
            </div>
          </div>
        </template>
        <template v-else-if="i.eventType == 'BROADCAST'">
          <div
            :class="{
              eventcontentItem: true,
              eventcontentItemActived: i.id === defaultMesssageId ? true : false,
            }"
            style="color: #efb63b"
            @click="clickdetails(i.id)"
          >
            <div style="width: 35%">
              <p style="margin-left: 10px">{{ i.title }}</p>
            </div>
            <div style="width: 15%">
              <p style="margin-left: 10px">
                {{ moment(i.eventTime).format("MM-DD") }}
              </p>
            </div>
            <div style="width: 50%">
              <p style="margin-left: 10px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                {{ i.content }}
              </p>
            </div>
          </div>
        </template>
        <template v-else-if="i.eventType == 'MESSAGE'">
          <div
            :class="{
              eventcontentItem: true,
              eventcontentItemActived: i.id === defaultMesssageId ? true : false,
            }"
            style="color: #29dbc9"
            @click="clickdetails(i.id)"
          >
            <div style="width: 35%">
              <p style="margin-left: 10px">{{ i.title }}</p>
            </div>
            <div style="width: 15%">
              <p style="margin-left: 10px">
                {{ moment(i.eventTime).format("MM-DD") }}
              </p>
            </div>
            <div style="width: 50%">
              <p style="margin-left: 10px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                {{ i.content }}
              </p>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { eventMessagelist } from "@/api/eventlog/index.js";
import dayjs from "dayjs";
import moment from "moment";
export default {
  name: "Eventstatistics",
  data() {
    return {
      data: null,
      contentdata: "",
      defaultMesssageId: "", //当前详情id
    };
  },
  components: {},
  props: ["datevalue"],
  watch: {
    datevalue(newName, oldName) {
      let _self = this;
      eventMessagelist({
        page: 1,
        pageSize: 10000,
        eventTime: dayjs(newName).format("YYYY-MM-DD"),
        eventType: "ALARM",
      }).then((res) => {
        this.contentdata = res.data;
        const { id } = res.data && res.data.eventMessageList && res.data.eventMessageList.length ? res.data.eventMessageList[0] : {};
        _self.clickdetails(id); //请求当前详情
      });
    },
  },
  methods: {
    moment,
    goBack() {},
    changedata(val) {
      let time = this.datevalue;
      if (this.datevalue == "") {
        time = dayjs(new Date()).format("YYYY-MM-DD");
      } else {
        time = dayjs(this.datevalue).format("YYYY-MM-DD");
      }
      if (val == "gaojing") {
        this.data = this.gaojingdata;
        eventMessagelist({
          page: 1,
          pageSize: 10000,
          eventTime: time,
          eventType: "ALARM",
        }).then((res) => {
          this.contentdata = res.data;
        });
      } else if (val == "guangbo") {
        this.data = this.guangbodata;
        eventMessagelist({
          page: 1,
          pageSize: 10000,
          eventTime: time,
          eventType: "BROADCAST",
        }).then((res) => {
          this.contentdata = res.data;
        });
      } else if (val == "tozhi") {
        this.data = this.tozhidata;
        eventMessagelist({
          page: 1,
          pageSize: 10000,
          eventTime: time,
          eventType: "MESSAGE",
        }).then((res) => {
          this.contentdata = res.data;
        });
      }
    },
    clickdetails(id) {
      if (!id && id !== 0) return;
      this.defaultMesssageId = id; //当前详情id
      this.$emit("EventstatisticsData", id);
    },
  },
  created() {
    let apivalue = {
      page: 1,
      pageSize: 10000,
      eventTime: dayjs(new Date()).format("YYYY-MM-DD"),
    };
    eventMessagelist({
      ...apivalue,
    }).then((res) => {
      this.contentdata = res.data;
    });
  },
  beforeUnmount() {},
};
</script>

<style lang="scss">
.statistics {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.eventclass {
  width: 85%;
  /* height: 78px; */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.eventclass > div {
  width: 30%;
  /* height: 100%; */
}
.eventred {
  width: 55px;
  height: 50px;
  background: url("../../assets/icons/Alarmicon.png") no-repeat;
  background-size: 100%;
  margin: 10px auto;
}
.eventyellow {
  width: 55px;
  height: 50px;
  background: url("../../assets/icons/BroadcastIcon.png") no-repeat;
  background-size: 100%;
  margin: 10px auto;
}
.eventgreen {
  width: 55px;
  height: 50px;
  background: url("../../assets/icons/LinkageIcon.png") no-repeat;
  background-size: 100%;
  margin: 10px auto;
}
.eventtitle {
  width: 95%;
  height: 38px;
  background: #5b657f;
  border-radius: 4px;
  margin: 18px auto;
  color: #fff;
  font-size: 18px;
  display: flex;
  line-height: 38px;
  margin-bottom: 10px;
}
.eventcontent {
  width: 95%;
  height: 124px;
  margin: 0 auto;
  overflow-y: scroll;
  .eventcontentItem {
    padding: 5px 2px;
    cursor: pointer;
  }
  .eventcontentItemActived {
    background: rgba(255, 255, 255, 0.1);
  }
}
.eventcontent > div {
  display: flex;
  margin-bottom: 10px;
}
</style>
