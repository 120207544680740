<template>
    <div class="Proportionevents" style="display: flex; width: 100%; height: 87%;">
        <div style="margin: auto;width: 80%;height: 70%;display: flex;justify-content: space-between;">
            <apexchart type="pie" width="200" :options="chartOptions" :series="series" height="180" style="float: left;"></apexchart>
            <div style="margin-top: 20px;">
                <div class="Proportiontext">
                    <h3 style="color: #D42D21;"> {{time.alarmNum}} <span>次</span></h3>
                    <p>告警事件总数</p>
                </div>
                <div class="Proportiontext">
                    <h3 style="color: #0F79E6;">{{time.alarmPerc}}<span>%</span></h3>
                    <p>占比数值</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.Proportionevents .apexcharts-pie-label{
    display: none;
}
.Proportionevents .apexcharts-legend.apx-legend-position-right{
    display: none;
} 
.Proportionevents .Proportiontext{
    margin-bottom:20px;
}  
.Proportiontext h3{
    font-size: 25px;
    font-weight: bold;
    margin-bottom:10px;
}
.Proportiontext span {
    font-size: 10px;
    font-weight: 500;
}
.Proportiontext p {
    color: #fff;
}
</style>

<script>

export default {
    name: "Proportionevents",
    data() {
        return {
            series: [0,100],
            chartOptions: {
                chart: {
                    type: 'pie',
                },
                labels: ['告警事件','其他'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            type:'pie'
                        }
                    }
                }]
            },
        };
    },
    components: {
    },
    props: ['time'],
    watch: {
        time(newvalue , oldvalue){
            this.series = [Number(newvalue.alarmPerc),100-newvalue.alarmPerc]
            console.log(newvalue.alarmPerc)
        }
    },
    methods: {
        goBack() {},
    },
    created() {
    },
    methods() {
        console.log()
        console.log(this.time)
    },
    beforeUnmount() {},
};
</script>