import request from "@/utils/request";

export function eventMessagelist(params) {
  return request({
    url: "api/platform/index/eventMessage/list",
    method: "get",
    params: params,
  });
}

export function eventMessagedetails(params) {
  return request({
    url: "api/platform/index/eventMessage/read",
    method: "get",
    params: params,
  });
}

export function eventMessagemonth(params) {
  return request({
    url: "api/platform/index/eventMessage/count/month",
    method: "get",
    params: params,
  });
}

export function eventMessageperc(params) {
  return request({
    url: "api/platform/index/eventMessage/count/perc",
    method: "get",
    params: params,
  });
}

export function eventMessageall() {
  return request({
    url: "api/platform/index/eventMessage/count/all",
    method: "get",
  });
}

export function eventMessageday(params) {
  return request({
    url: "api/platform/index/eventMessage/count/day",
    method: "get",
    params: params,
  });
}

export function gettime() {
  return request({
    url: "api/platform/index/flush/get",
    method: "get",
  });
}
